import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

@customElement("form-input")
export class FormInput extends LitElement {
  static styles = css`
  :host { 
      display: contents; 
    }
  `;

  @property() name: string;
  @property() query: string;
  @property() property: string;
  // @property() for:string;

  @property({type:Boolean}) stringify:boolean = false;

  constructor() {
    super();
    this._handleFormDataEvent = this._handleFormDataEvent.bind(this);
  }

  connectedCallback() {
    super.connectedCallback()
    this.closest("form").addEventListener("formdata", this._handleFormDataEvent);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    // no idea it's been removed, or is being removed, and it won't be able to find the form if it's already removed
    this.closest("form").removeEventListener("formdata", this._handleFormDataEvent);
  }

  protected _slottedChildren() {
    const slot = this.shadowRoot.querySelector('slot');
    const childNodes = slot.assignedNodes({ flatten: true });
    return childNodes.filter((node: Node) => node.nodeType == Node.ELEMENT_NODE) as Element[];
  }

  protected _handleFormDataEvent(event: FormDataEvent) {
    const propertyName = this.property ? this.property : "value";
    const nodes = this.query ? this.querySelectorAll(this.query) : this._slottedChildren();


    //remove any existing formdata
    nodes.forEach(n => {
      event.formData.delete((n as any)["name"] ?? this.name);
    })

    nodes.forEach(n => {
      let name = (n as any)["name"];
      if (name == null || name === '') {
        name = this.name;
      }

      const formValue =this.stringify ?  JSON.stringify((n as any)[propertyName]) : (n as any)[propertyName];
      event.formData.append(name ?? this.name, formValue);
    })
  }

  render() {
    return html`
           <slot></slot>
        `;
  }
}