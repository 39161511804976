import { LitElement, html, css } from 'lit';
import { customElement, property, query } from 'lit/decorators.js'
import { CloseViewEvent } from 'src/events/close-view';

@customElement('dialog-wrapper')
export class DialogWrapper extends LitElement {
  static styles = [
    css`
      :host {
        display: block;
      }
    `
  ];

  @query("#dialog") dialog:HTMLDialogElement 

  openDialogHandler = (e:Event) =>{
    e.stopPropagation();
    e.preventDefault();
    this.dialog.showModal();
  }

  closeEventHandler = (e:CloseViewEvent) => { 
    this.dialog.close(); 
    e.stopPropagation();    
    e.preventDefault(); 
  }
  
  render() {
    return html`
      <slot @click=${this.openDialogHandler}></slot>
      <dialog id="dialog" @close-view=${this.closeEventHandler}>
        <slot name="dialog-content"></slot>
      </dialog>
    `;
  }
}
